<template>
  <div class="pageContol studentList TrainingRecordsSee">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >培训档案</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">培训档案预览</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 4rem">姓名:</span>
              <el-input
                class="listInput"
                v-model="searchData.userName"
                placeholder="请输入姓名"
                size="small"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                class="listInput"
                v-model="searchData.idcard"
                placeholder="请输入身份证号"
                size="small"
                clearable
              />
            </div>
            <div title="电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 4rem">电话:</span>
              <el-input
                class="listInput"
                v-model="searchData.mobile"
                placeholder="请输入电话"
                size="small"
                clearable
              />
            </div>
            <div title="是否合格" class="searchboxItem ci-full">
              <span class="itemLabel">是否合格:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.eligible"
                placeholder="请选择是否合格"
                clearable
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding: 0 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="generateStatement()"
                >下载学员档案</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              row-key="userId"
              style="width: 100%"
              @selection-change="checkListData"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                label="身份证号"
                align="center"
                show-overflow-tooltip
                prop="idcard"
                minWidth="180"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="是否合格"
                align="center"
                show-overflow-tooltip
                prop="eligible"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.eligible ? "是" : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="用户照片"
                align="center"
                prop="headPath"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span v-if="!scope.row.headPath">--</span>
                  <el-image
                    v-else
                    class="theImgno"
                    style="width: 62px; height: 80px; min-width: 62px"
                    :src="scope.row.headPath"
                    :preview-src-list="[scope.row.headPath]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="认证照片"
                align="center"
                prop="approveFaces"
                :minWidth="lengthWidth"
              >
                <template slot-scope="scope">
                  <!-- {{ scope.row }} -->
                  <span v-if="!scope.row.approveFaces">--</span>
                  <!-- <div v-else style="display: flex">
                    <el-image
                      v-for="(item, index) in scope.row.approveFaces"
                      :key="index"
                      class="theImgno"
                      style="width: 40px; height: 50px; margin: 0 5px;min-width:40px"
                      :src="item.url"
                      :preview-src-list="[item.url]"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-folder-delete"></i>
                      </div>
                    </el-image> -->
                  <div v-else>
                    <el-upload
                      action
                      :file-list="scope.row.approveFaces"
                      list-type="picture-card"
                      :on-change="handlePictureCardPreview"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      class="df"
                      :class="{ hide: hideUploadCard }"
                      :on-remove="handleRemove"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file, scope.row)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="400px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <!-- <el-button
                    type="text"
                    size="mini"
                    @click="ScanImage(scope.row)"
                    >扫描图片</el-button
                  > -->
                  <el-button type="text" size="mini" @click="open(scope.row, 1)"
                    >档案详情</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="fileVoid(scope.row)"
                    >档案作废</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style=""
                    @click="open(scope.row, 2)"
                    >{{
                      scope.row.eligible ? "设为不合格" : "设为合格"
                    }}</el-button
                  >
                  <el-button type="text" size="mini" @click="open(scope.row, 3)"
                    >人脸审核</el-button
                  >
                  <el-button
                    v-if="scope.row.userDetailUrl"
                    type="text"
                    size="mini"
                    @click="open(scope.row, 4)"
                    >郑州明细</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogtitle"
      :visible.sync="dialogDownload"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 800px"></div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" top="2%" title="认证照片">
      <div>
        <img width="100%" :src="dialogImageUrl" alt="" height="100%" />
      </div>
    </el-dialog>
    <el-dialog
      title="全员培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormall"
              :model="docsFormall"
              class="docsDialog"
              v-show="docsOptionall.length"
            >
              <el-form-item v-for="(item, index) in docsOptionall" :key="index">
                <el-checkbox v-model="docsFormall[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog_1"
            >确定</el-button
          >
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 替换认证照片 -->
    <el-dialog
      :visible.sync="certifiedPhoto.dialogVisible"
      top="10%"
      width="1240px"
      title="替换认证照片"
      :before-close="certifiedPhotoClose"
    >
      <div class="certifiedPhoto">
        <div>
          <el-button
            type="primary"
            class="bgc-bv"
            round
            size="small"
            @click="defAll"
            >{{
              certifiedPhoto.certifiedPhotoData.queryType == "10"
                ? "显示全部"
                : "显示默认"
            }}</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            size="small"
            @click="okCertifiedPhoto"
            >确认更换</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            size="small"
            @click="certifiedPhotoClose"
            >取消</el-button
          >
        </div>
        <div class="zpShell">
          <div
            class="zpList"
            v-for="(item, index) in certifiedPhoto.certifiedPhotoList"
            :key="index"
            @click="clickImg(item.faceId)"
          >
            <img :src="item.approveFace" alt="" />
            <p class="p2">{{ item.createTime | momentDate }}</p>
            <p class="p1">{{ item.catalogName }}</p>
            <i class="el-icon-success" v-if="item.faceId == certifiedPhoto.replaceFaceId"></i>
          </div>
          <div class="noData">
            <img v-if="(certifiedPhoto.certifiedPhotoList.length<1)" src="../../assets/empty.png" alt="">
            <p>暂无数据...</p>
          </div>
          
        </div>
        <div class="pageNum">
          <el-pagination
            :total="certifiedPhoto.fenYe.total"
            :page-size="certifiedPhoto.fenYe.size"
            :current-page="certifiedPhoto.fenYe.currentPage"
            background
            layout="total,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "trainingManagement/TrainingRecordsSee",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        projectId: this.$route.query.projectId, //班级id
        userName: "", //姓名
        idcard: "", //身份证号
        mobile: "", //手机号
        eligible: "", //
      },
      statusList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "合格",
        },
        {
          value: "0",
          label: "不合格",
        },
      ], //
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogDownload: false,
      dialogtitle: "",
      lengthWidth: 0,
      hideUploadCard: true,
      dialogVisible: false,
      dialogImageUrl: "",
      multipleTable: [],
      docsDialogVisible: false,
      docsOptionall: [],
      docsFormall: {},
      agreeState: false, //弹框是否同意
      // 认证照片 - 相关操作/数据
      certifiedPhoto: {
        // 认证照片 - 弹框
        dialogVisible: false,
        // 认证照片 - 列表数据
        certifiedPhotoList: [],
        // 认证照片 - 要替换照片的id
        replaceFaceId: "",
        // 认证照片 - 查询数据
        certifiedPhotoData: {
          userId: "", // 学员ID
          projectId: "", // 班级ID
          projectCourseId: "", // 班级课程ID
          faceId: "", // 人脸ID
          queryType: "10", // 是否显示全部：10-默认 20-全部
        },
        // 分页
        fenYe: {
          currentPage: 1,
          size: 10,
          total: null,
        },
      },
    };
  },
  created() {
    this.getTableHeight();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    showDocsDialog(raw) {
      this.docsDialogVisibleAll = true;
      this.selectedItem.id = raw.projectId;
      this.selectedItem.name = raw.projectName;
      this.trainTypeId = raw.trainTypeId;
      this.day = raw.endDate;
      let day = this.day.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.dayTure = true;
      }
    },
    // confirmDocsDialog() {
    //   let params = {
    //     fileDTOS: [],
    //   };
    //   params.fileDTOS.push({
    //     fileType: "20",
    //     fileName: this.$route.query.projectName + "-学员培训档案",
    //     className: "eduCertService",
    //     param: {
    //       projectId: this.$route.query.projectId,
    //     },
    //   });
    //   this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
    //     if (re.status == "0") {
    //       let list = re.data;
    //       for (let item of list) {
    //         if (!this.downloadItems.includes(item.taskId)) {
    //           this.$store.dispatch("pushDownloadItems", item.taskId);
    //         } else {
    //           this.$message.warning(
    //             "[" + item.fileName + "]已经申请下载,请耐心等待"
    //           );
    //         }
    //       }
    //     } else {
    //       this.$message.error(re.message);
    //     }
    //   });
    // },
    //跳转
    open(row, type) {
      //档案详情
      if (type == "1") {
        let parmar = {
          projectId: row.projectId,
          userId: row.userId,
        };
        this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
          if (res.status == 0) {
            this.dialogDownload = true;
            this.dialogtitle = "查看学员档案";
            this.$nextTick(() => {
              pdf.embed(res.data, "#pdf-content");
            });
          } else {
            that.$message.warning({ message: res.message, duration: 1500 });
          }
        });
      }

      //档案是否合格
      if (type == "2") {
        //是否合格
        let parmar = {
          projectUserCertId: row.projectUserCertId,
          eligible: !row.eligible,
          userId: row.userId,
          projectId: row.projectId,
        };
        this.$post("/run/project/setCertEligible", parmar).then((res) => {
          if (res.status == 0) {
            if (row.eligible) {
              this.$message({
                type: "success",
                message: "该档案成功标记为不合格档案",
                duration: 3000,
              });
            } else {
              this.$message({
                type: "success",
                message: "该档案成功标记为合格档案",
                duration: 3000,
              });
            }
            this.getData(-1);
          } else {
            that.$message.warning({ message: res.message, duration: 1500 });
          }
        });
      }
      //人脸审核
      if (type == "3") {
        this.$router.push({
          path: "/web/trainingManagement/faceCheck",
          query: {
            userId: row.userId,
            projectId: row.projectId,
          },
        });
      }
      if (type == 4) {
        let routeUrl = this.$router.resolve({
          path: "/web/zhengzhouH5/logs2",
          query: {
            json: row.userDetailUrl,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    getData(pageNum = 1) {
      const params = {
        projectId: this.$route.query.projectId,
        pageSize: this.pageSize,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.eligible) {
        params.eligible = this.searchData.eligible;
      }
      this.doFetch({
        url: "/run/project/viewCertList",
        params,
        pageNum,
      });
    },
    async oneKeyGet(tableData) {
      for (let i in tableData) {
        let params = {
          projectCourseId: tableData[i].projectCourseId,
          projectId: tableData[i].projectId,
          userId: tableData[i].userId,
        };
        await this.$post(
          "/run/project/viewCertImageList",
          params,
          3000,
          false
        ).then((res) => {
          if (res.status == 0) {
            let data = [];
            res.data.map((el) => {
              data.push({
                name: el.faceId,
                url: el.url,
                projectCourseId: el.projectCourseId,
                original: el.original,
              });
            });
            this.$set(this.tableData[i], "approveFaces", [...data]);
            var max = this.tableData[0].approveFaces.length || 0;
            setTimeout(() => {
              for (var j = 1; j < this.tableData.length; j++) {
                if (
                  this.tableData[j].approveFaces &&
                  this.tableData[j].approveFaces.length > max
                ) {
                  max = this.tableData[j].approveFaces.length;
                }
              }
              this.lengthWidth = 100 * max;
            }, 300);

            // this.lengthWidth = 100*11
          }
        });
      }
    },
    // 照片单独加载
    handleListDataBack(tableData) {
      this.tableData = tableData;
      this.oneKeyGet(tableData);
    },
    ScanImage(row) {
      this.$post("/scan/archives/start", {
        projectId: row.projectId,
        userId: row.userId,
      }).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            type: "success",
            message: ret.message,
            duration: 3000,
          });
        }
      });
    },
    // 预览照片
    handlePictureCardPreview(file) {
      if (file.url) {
        this.dialogImageUrl = file.original;
        this.dialogVisible = true;
      } else {
        this.$message({
          type: "error",
          message: "无可预览的认证照片",
          duration: 3000,
        });
      }
    },
    // 替換照片
    handleRemove(file, row) {
      this.certifiedPhoto.certifiedPhotoData.userId = row.userId;
      this.certifiedPhoto.certifiedPhotoData.projectId = row.projectId;
      this.certifiedPhoto.certifiedPhotoData.projectCourseId =
        file.projectCourseId;
      this.certifiedPhoto.certifiedPhotoData.faceId = file.name;
      this.$post("/scan/archives/replace/face/pageList", {
        ...this.certifiedPhoto.certifiedPhotoData,
        ...{
          pageSize: this.certifiedPhoto.fenYe.size,
          pageNum: this.certifiedPhoto.fenYe.currentPage,
        },
      }).then((res) => {
        if (res.status == 0) {
          this.certifiedPhoto.dialogVisible = true;
          this.certifiedPhoto.certifiedPhotoList = res.data.list;
          this.certifiedPhoto.fenYe.total = res.data.total;
        }
      }).catch(err=>{
        this.certifiedPhoto.dialogVisible = false;
      });
    },
    // 替換照片 - 每页多少条
    handleSizeChange(val) {
      this.certifiedPhoto.fenYe.size = val;
      this.certifiedPhoto.fenYe.currentPage = 1;
      this.$post("/scan/archives/replace/face/pageList", {
        ...this.certifiedPhoto.certifiedPhotoData,
        ...{
          pageSize: this.certifiedPhoto.fenYe.size,
          pageNum: this.certifiedPhoto.fenYe.currentPage,
        },
      }).then((res) => {
        if (res.status == 0) {
          this.certifiedPhoto.certifiedPhotoList = res.data.list;
          this.certifiedPhoto.fenYe.total = res.data.total;
        }
      });
    },
    // 替換照片 - 当前页数
    handleCurrentChange(val) {
      this.certifiedPhoto.fenYe.currentPage = val;
      this.$post("/scan/archives/replace/face/pageList", {
        ...this.certifiedPhoto.certifiedPhotoData,
        ...{
          pageSize: this.certifiedPhoto.fenYe.size,
          pageNum: this.certifiedPhoto.fenYe.currentPage,
        },
      }).then((res) => {
        if (res.status == 0) {
          this.certifiedPhoto.certifiedPhotoList = res.data.list;
          this.certifiedPhoto.fenYe.total = res.data.total;
        }
      });
    },
    // 替换照片 - 默认/全部按钮
    defAll() {
      this.certifiedPhoto.certifiedPhotoData.queryType =
        this.certifiedPhoto.certifiedPhotoData.queryType == "10" ? "20" : "10";
      this.handleCurrentChange(1);
    },
    // 替换照片 - 点击当前图片
    clickImg(replaceFaceId) {
      if(this.certifiedPhoto.replaceFaceId && this.certifiedPhoto.replaceFaceId == replaceFaceId) {
        this.certifiedPhoto.replaceFaceId = "";
      } else {
        this.certifiedPhoto.replaceFaceId = replaceFaceId;
      }
    },
    // 替换照片 - 确认替换按钮
    okCertifiedPhoto() {
      if(this.certifiedPhoto.replaceFaceId){
        this.$post("/scan/archives/replace/face", {
          faceId: this.certifiedPhoto.certifiedPhotoData.faceId,
          replaceFaceId: this.certifiedPhoto.replaceFaceId,
        }).then((res) => {
          if (res.status == 0) {
            this.getData(this.pageNum);
            this.certifiedPhoto.dialogVisible = false;
            this.certifiedPhoto.fenYe.currentPage = 1;
            this.certifiedPhoto.certifiedPhotoData.queryType = "10";
          }
        });
      } else {
        this.$message({
          message: "您还未选择要替换的照片",
          type: "warning",
        });
      }
    },
    // 替换照片 - 取消
    certifiedPhotoClose() {
      this.certifiedPhoto.dialogVisible = false;
      this.certifiedPhoto.fenYe.currentPage = 1;
      this.certifiedPhoto.certifiedPhotoData.queryType = "10";
      this.certifiedPhoto.replaceFaceId = "";
    },
    // 档案作废
    fileVoid(row) {
      this.$post("/run/project/student/cancellation", {
        projectId: row.projectId,
        userId: row.userId,
      }).then((ret) => {
        if (ret.status == "0") {
          this.$message({
            message: ret.message,
            type: "success",
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    // 下载档案
    generateStatement() {
      if (this.multipleTable.length > 0) {
        let userIds = [];
        userIds = this.multipleTable.map((t) => {
          return t.userId;
        });
        this.userIds = userIds;
        this.docsDialogVisible = true;
        this.$post("/run/project/student/archives", {
          projectId: this.$route.query.projectId,
        })
          .then((res) => {
            if (res.status == 0) {
              this.docsOptionall = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    confirmDocsDialog_1() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormall) {
        if (this.docsFormall[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName: this.docsOptionall.find((el) => el.dictCode == i)
              .dictValue,
            // className: className,
            personage: true,
            param: {
              projectId: this.$route.query.projectId,
            },
            userIds: this.userIds,
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormall"].resetFields();
      for (let key in this.docsFormall) {
        this.docsFormall[key] = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  .el-table__fixed-right {
    height: auto !important; // 此处的important表示优先于element.style
    bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
  }
}
.TrainingRecordsSee /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}

.TrainingRecordsSee /deep/.el-upload-list.el-upload-list--picture-card {
  display: flex;
}
.hide /deep/.el-upload--picture-card {
  display: none !important;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.certifiedPhoto {
  height: 350px;
  position: relative;
  .zpShell {
    display: flex;
    margin: 20px 0;
    .zpList {
      width: 110px;
      margin: 0 5px;
      cursor: pointer;
      position: relative;
      img {
        width: 110px;
        height: 130px;
      }
      .p1 {
        // overflow: hidden; //超出的文本隐藏
        // display: -webkit-box;
        // -webkit-line-clamp: 2; // 超出多少行
        // -webkit-box-orient: vertical;
      }
      .p2 {
        font-size: 12px;
        margin-top: 10px;
      }
      i {
        color: #409EFF;
        font-size: 28px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .noData {
      width: 100%;
      img {
        width: 230px;display: block; margin: auto;
      }
      p {
        color: #a3a3a3;
        text-align: center;
      }
    }
  }
  .pageNum {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
